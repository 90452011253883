import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { truncate } from "lodash"
import PropTypes from "prop-types"
import { HiOutlineArrowCircleRight } from "react-icons/hi"
import { Link, useStaticQuery, graphql } from "gatsby"

const GuideListCard = ({ featuredimage, title, href, description }) => {
  return (
    <div className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
      <div className="flex flex-column">
        <Link className="w-100" to={href}>
          <Image
            fluid={{
              ...featuredimage.childImageSharp.fluid,
              aspectRatio: 16 / 9,
            }}
            alt={title}
          />
        </Link>
        <Link className="title text-black" to={href}>
          <h2 className="py-4 font-size-lg-4 font-medium">{title}</h2>
        </Link>
        <div className="d-flex">
          <p>{truncate(description, { length: 70 })}</p>
          <Link
            className="flex-shrink-0 mx-3 font-size-lg-6 text-black"
            to={href}
          >
            <HiOutlineArrowCircleRight />
          </Link>
        </div>
      </div>
    </div>
  )
}

GuideListCard.propTypes = {
  featuredimage: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

const GuideList = ({ titles }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "guide-main" } } }
      ) {
        edges {
          node {
            frontmatter {
              list {
                title
                href
                description
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  // const filteredPosts = data.allMarkdownRemark.edges.filter(edge => {
  //   return !!titles.find(title => title === edge.node.frontmatter.title)
  // })

  const guideMainList = data.allMarkdownRemark.edges[0].node.frontmatter.list

  return (
    <div className="row">
      {guideMainList.map(item => {
        return (
          <GuideListCard
            featuredimage={item.featuredimage}
            title={item.title}
            href={item.href}
            description={item.description}
          />
        )
      })}
    </div>
  )
}

GuideList.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
}

export default GuideList
