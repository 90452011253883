import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"

const BlogListCard = ({ post }) => {
  const { featuredimage, title, date } = post.frontmatter
  return (
    <div className="col-12 col-md-6 col-lg-4 mb-5">
      <div className="flex flex-column">
        <Link className="w-100" target="_blank" to={post.fields.slug}>
          <Image
            fluid={{
              ...featuredimage.childImageSharp.fluid,
              aspectRatio: 3 / 2,
            }}
            alt={title}
          />
        </Link>
        <Link className="title text-black" to={post.fields.slug}>
          <h2 className="py-4 font-size-lg-4 font-medium">{title}</h2>
        </Link>
        <p>{post.excerpt}</p>
        <span className="mb-4">
          {date} · {post.timeToRead} min read
        </span>
      </div>
    </div>
  )
}

BlogListCard.propTypes = {
  post: PropTypes.object,
}

const BlogList = ({ category }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
        edges {
          node {
            timeToRead
            excerpt(pruneLength: 100)
            id
            fields {
              slug
            }
            frontmatter {
              title
              category
              templateKey
              date(formatString: "DD MMMM YYYY")
              featuredpost
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const filteredPosts = data.allMarkdownRemark.edges.filter(edge => {
    return edge.node.frontmatter.category === category
  })

  return (
    <div className="d-flex flex-column mb-8">
      <h2 className="mb-5">{category}</h2>
      <div className="row">
        {filteredPosts.map((post, i) => {
          return <BlogListCard post={post.node} key={`blogList-${i}`} />
        })}
      </div>
    </div>
  )
}

BlogList.propTypes = {
  category: PropTypes.string.isRequired,
}

export default BlogList
