import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BlogList from "../components/BlogList"
import GuideList from "../components/GuideList"
import { DownloadBanner } from "../components/DownloadBanner"

const Information = ({ path }) => (
  <Layout loadMap={false}>
    <SEO
      path={path}
      title="Information - EstatesPlus - Wills, Probate & Letters of Administration. Save up to 90% on legal fees"
      description="Information We write and curate helpful content to help you deal with Deceased Estates and Wills in Australia How to Sign Your Will In Victoria During COVID-19  In order to maintain social distancing and in some cases, remain in our homes, the Victorian Government has introduced the COVID-19 Omnibus (Emergency Measures) (Electronic Signing and Witnessing)"
      image="/img/signing-during-covid-19-victoria-copy-1024x683.jpeg"
    />
    <section className="bg-white pt-8">
      <div className="container">
        <h2 className="mb-3">Information/Guides</h2>
        <p className="mb-5">
          We write and curate helpful content to help you deal with Deceased
          Estates, Cremation, Wills & Estate Planning in Australia.
        </p>
      </div>
    </section>

    <section>
      <div className="container">
        <h2 className="mb-5">In-Depth Guides</h2>
        {/* Just pass the title of the guide */}
        <GuideList
          titles={["Getting Financial & Emotional Support When Someone Dies"]}
        />
      </div>
    </section>

    <section className="section-gap">
      <div className="container">
        <BlogList category="Wills & Estate Planning" />
        <BlogList category="Deceased Estates" />
      </div>
    </section>

    <DownloadBanner />
  </Layout>
)

export default Information
