import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import "./banner-styles.scss"

export function DownloadBanner() {
  return (
    <section className="bg-light pb-3 p-lg-5">
      <section className="container shadow-lg">
        <div className="row center banner-cont rounded-lg overflow-hidden py-3">
          <div
            className="col-sm-10 col-md-10 col-lg-10"
            style={{ display: "flex", alignItems: "center" }}
          >
            <p className="banner-title">
              Download Our Free App - The All-In-One Guide To Managing A
              Deceased Estate
            </p>
          </div>
          <div className="col-sm-2 col-md-2 col-lg-2 p-0 d-flex justify-content-center">
            <Link to="/lost-a-loved-one/download-app">
              <Button size="sm" className="banner-btn">
                Free Download
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </section>
  )
}
